import React from 'react';
import './NotFound.css';
import Button from '../../components/Button/Button';

export default function NotFound() {
  return (
    <div className="notFound">
      <p className="notFound__text">404</p>
      <p className="notFound__description">сХОЖЕ, ЩО ТАКОЇ СТОРІНКИ НЕ ІСНУЄ</p>
      <Button />
    </div>
  );
}
