import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainRoute from './pages/MainRoute';
import { Context } from './Context';
import './fonts.css';
import './App.css';

function App() {
  // лічильник корзини
  const [busketNumber, setBusketNumber] = useState(false);

  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);

  // активация формы
  const [formActive, setFormActive] = useState(false);

  // записываем корзину
  useEffect(() => {
    const atomizer = localStorage.getItem('atomizerItems');
    if (atomizer) {
      setBusketNumber(true);
    }
  }, []);

  return (
    <Context.Provider
      value={{
        busketNumber,
        setBusketNumber,
        one, setOne,
        two, setTwo,
        three, setThree,
        formActive, setFormActive
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MainRoute />} />
        </Routes>
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
