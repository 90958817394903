export const data = [
  // черный
  {
    link: 'thin/2ml-black',
    category: 'thin',
    ml: '2',
    img: '/img/thin_2ml_bl.png',
    color: 'black',
    price: '14.40',
    opt50: '13',
    opt100: '12',
    opt200: '11',
    opt500: '10',
  },
  // {
  //   link: 'thin/3ml-black',
  //   category: 'thin',
  //   ml: '3',
  //   img: '/img/thin_3ml_bl.png',
  //   color: 'black',
  //   price: '15.60',
  //   opt50: '14',
  //   opt100: '13',
  //   opt200: '12',
  //   opt500: '11',
  // },
  {
    link: 'thin/5ml-black',
    category: 'thin',
    ml: '5',
    img: '/img/thin_6ml_bl.png',
    color: 'black',
    price: '16.80',
    opt50: '15',
    opt100: '14',
    opt200: '13',
    opt500: '12',
  },
  {
    link: 'thin/10ml-black',
    category: 'thin',
    ml: '10',
    img: '/img/thin_10ml_bl.png',
    color: 'black',
    price: '18',
    opt50: '16',
    opt100: '15',
    opt200: '14',
    opt500: '13',
  },
  // золотой
  {
    link: 'thin/2ml-gold',
    category: 'thin',
    ml: '2',
    img: '/img/thin_2ml_gold.png',
    color: 'gold',
    price: '14.40',
    opt50: '13',
    opt100: '12',
    opt200: '11',
    opt500: '10',
  },
  // {
  //   link: 'thin/3ml-gold',
  //   category: 'thin',
  //   ml: '3',
  //   img: '/img/thin_3ml_gold.png',
  //   color: 'gold',
  //   price: '15.60',
  //   opt50: '14',
  //   opt100: '13',
  //   opt200: '12',
  //   opt500: '11',
  // },
  {
    link: 'thin/5ml-gold',
    category: 'thin',
    ml: '5',
    img: '/img/thin_6ml_gold.png',
    color: 'gold',
    price: '16.80',
    opt50: '15',
    opt100: '14',
    opt200: '13',
    opt500: '12',
  },
  {
    link: 'thin/10ml-gold',
    category: 'thin',
    ml: '10',
    img: '/img/thin_10ml_gold.png',
    color: 'gold',
    price: '18',
    opt50: '16',
    opt100: '15',
    opt200: '14',
    opt500: '13',
  },
  // серебряный
  {
    link: 'thin/2ml-silver',
    category: 'thin',
    ml: '2',
    img: '/img/thin_2ml_silv.png',
    color: 'silver',
    price: '14.40',
    opt50: '13',
    opt100: '12',
    opt200: '11',
    opt500: '10',
  },
  // {
  //   link: 'thin/3ml-silver',
  //   category: 'thin',
  //   ml: '3',
  //   img: '/img/thin_3ml_silv.png',
  //   color: 'silver',
  //   price: '15.60',
  //   opt50: '14',
  //   opt100: '13',
  //   opt200: '12',
  //   opt500: '11',
  // },
  {
    link: 'thin/5ml-silver',
    category: 'thin',
    ml: '5',
    img: '/img/thin_6ml_silv.png',
    color: 'silver',
    price: '16.80',
    opt50: '15',
    opt100: '14',
    opt200: '13',
    opt500: '12',
  },
  {
    link: 'thin/10ml-silver',
    category: 'thin',
    ml: '10',
    img: '/img/thin_10ml_silv.png',
    color: 'silver',
    price: '18',
    opt50: '16',
    opt100: '15',
    opt200: '14',
    opt500: '13',
  },
  // черный толстый
  // вторые
  // {
  //   link: 'thick/4ml-black',
  //   category: 'thick',
  //   ml: '4',
  //   img: '/img/item-1.png',
  //   color: 'black',
  //   price: '18',
  //   opt50: '16.80',
  //   opt100: '16.20',
  //   opt200: '15.30',
  //   opt500: '15.30',
  // },
  // золотой
  // {
  //   link: 'thick/4ml-gold',
  //   category: 'thick',
  //   ml: '4',
  //   img: '/img/item-1.png',
  //   color: 'gold',
  //   price: '18',
  //   opt50: '16.80',
  //   opt100: '16.20',
  //   opt200: '15.30',
  //   opt500: '15.30',
  // },
  {
    link: 'thick/6ml-gold',
    category: 'thick',
    ml: '6',
    img: '/img/5ml_gold.png',
    color: 'gold',
    price: '19.20',
    opt50: '18.60',
    opt100: '17.40',
    opt200: '16.50',
    // opt500: '16.50',
  },
  {
    link: 'thick/10ml-gold',
    category: 'thick',
    ml: '10',
    img: '/img/10ml_gold.png',
    color: 'gold',
    price: '21.9',
    opt50: '20.70',
    opt100: '19.80',
    opt200: '19.10',
    // opt500: '19.10',
  },
  {
    link: 'thick/15ml-gold',
    category: 'thick',
    ml: '15',
    img: '/img/15ml_gold.png',
    color: 'gold',
    price: '22.5',
    opt50: '21.6',
    opt100: '20.80',
    opt200: '19.80',
    // opt500: '19.80',
  },
  {
    link: 'thick/20ml-gold',
    category: 'thick',
    ml: '20',
    img: '/img/20ml_gold.png',
    color: 'gold',
    price: '24.5',
    opt50: '24.5',
    opt100: '24.5',
    opt200: '24.5',
    // opt500: '24.5',
  },
  {
    link: 'thick/30ml-gold',
    category: 'thick',
    ml: '30',
    img: '/img/30ml_gold.png',
    color: 'gold',
    price: '25.5',
    opt50: '25.5',
    opt100: '25.5',
    opt200: '25.5',
    // opt500: '25.5',
  },
  // серебряный
  // {
  //   link: 'thick/4ml-silver',
  //   category: 'thick',
  //   ml: '4',
  //   img: '/img/5ml_sвilv.png',
  //   color: 'silver',
  //   price: '18',
  //   opt50: '16.80',
  //   opt100: '16.20',
  //   opt200: '15.30',
  //   opt500: '15.30',
  // },
  {
    link: 'thick/6ml-silver',
    category: 'thick',
    ml: '6',
    img: '/img/5ml_silv.png',
    color: 'silver',
    price: '19.20',
    opt50: '18.60',
    opt100: '17.40',
    opt200: '16.50',
    // opt500: '16.50',
  },
  {
    link: 'thick/10ml-silver',
    category: 'thick',
    ml: '10',
    img: '/img/10ml_silv.png',
    color: 'silver',
    price: '21.9',
    opt50: '20.70',
    opt100: '19.80',
    opt200: '19.10',
    // opt500: '19.10',
  },
  {
    link: 'thick/15ml-silver',
    category: 'thick',
    ml: '15',
    img: '/img/15ml_silv.png',
    color: 'silver',
    price: '22.5',
    opt50: '21.6',
    opt100: '20.80',
    opt200: '19.80',
    // opt500: '19.80',
  },
  {
    link: 'thick/20ml-silver',
    category: 'thick',
    ml: '20',
    img: '/img/20ml_silv.png',
    color: 'silver',
    price: '24.5',
    opt50: '24.5',
    opt100: '24.5',
    opt200: '24.5',
    // opt500: '24.5',
  },
  {
    link: 'thick/30ml-silver',
    category: 'thick',
    ml: '30',
    img: '/img/30ml_silv.png',
    color: 'silver',
    price: '25.5',
    opt50: '25.5',
    opt100: '25.5',
    opt200: '25.5',
    // opt500: '25.5',
  },
];