export const api_key_NP = '23fa6601f08c553d1487f0cabb99b02d';

export const inputs = [
  { name: 'lastName', label: 'Ваше прізвище', type: 'text' },
  { name: 'firstName', label: "Ваше ім'я", type: 'text' },
  { name: 'phone', label: 'Ваш телефон', type: 'tel' },
  { name: 'email', label: 'E-mail', type: 'email' },
];

export const payType = [
  { title: 'За допомогою платіжного сервісу (платіжної системи, платіжного інструменту) WayForPay', index: 1 },
  { title: 'Переказ грошей на банківський поточний рахунок Продавця', index: 2 }
];

export const deliveryType = [
  { text: 'Самовивіз з відділення Нової Пошти', secondText: '(оплата за тарифами перевізника)', index: 1 },
  { text: 'Адресна доставка Новою Поштою', secondText: '(оплата за тарифами перевізника)', index: 2 },
];

export const homeDevInputs = [
  { name: 'city', label: 'Ваше місто/населений пункт', type: 'text' },
  { name: 'region', label: 'Область', type: 'text' },
  { name: 'street', label: 'Адреса (назва вулиці та номер будинку)', type: 'text' },
  { name: 'appart', label: 'Квартира', type: 'text' },
];
