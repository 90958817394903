import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';

export default function Button() {
  return (
    <Link to={'/'} className="button">
      <img className="button-img" src="/img/icons/arrow-white.svg" alt="назад" />
      Повернутись на Головну
    </Link>
  );
}
