import React, { useEffect, useState } from 'react';
import './Form.css';
import axios from 'axios';
import { Alert, Snackbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export default function Form({ props }) {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openForm, setOpenForm] = useState(false);

  const isIpad = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    if (props) {
      setTimeout(() => {
        setOpenForm(true);
      }, 400);
    } else {
      setOpenForm(false);
    }
  }, [props]);

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    comment: '',
    agreement: false,
  });

  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    email: false,
    comment: false,
    agreement: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    if (name === 'agreement' && checked) {
      setErrors({ ...errors, agreement: false });
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: !formData.name,
      phone: !formData.phone,
      email: !formData.email,
      comment: !formData.comment,
      agreement: !formData.agreement,
    };
    setErrors(newErrors);
    return !newErrors.name && !newErrors.phone && !newErrors.agreement && !newErrors.email && !newErrors.comment;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const url = 'https://atomizer-backend.onrender.com/telegram-message';
      const submissionData = {
        Імʼя: formData.name,
        Телефон: formData.phone,
        Email: formData.email,
        Коментар: formData.comment,
      };
      axios
        .post(url, submissionData)
        .then((response) => {
          setOpenAlert(true);
          setAlertMessage('Ми отримали ваше повідомлення');

          setFormData({
            name: '',
            phone: '',
            email: '',
            comment: '',
            agreement: false,
          });
        })
        .catch((error) => {
          console.error('There was an error submitting the form!', error);
        });
    }
  };

  return (
    <div className={`form ${props ? 'form-active' : ''}`}>
      {!isIpad && (
        <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Alert
            onClose={handleAlertClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%', backgroundColor: 'white', color: '#28292D', fontFamily: 'Cygre', borderRadius: '33px' }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      )}

      <form className={`form__wrapper ${openForm ? 'form__wrapper-active' : ''}`} onSubmit={handleSubmit}>
        <div className="form__title">Хотіли би задати нам пару питань?</div>
        <div className="form__wrapper-title">Ваше ім’я</div>

        <input
          className={`form-input ${errors.name ? 'error' : ''}`}
          placeholder={errors.name ? 'Як до вас звертатись ?' : ''}
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoComplete="name"
        />

        <div className="form__wrapper-title">Моб. телефон</div>

        <input
          className={`form-input ${errors.phone ? 'error' : ''}`}
          placeholder={errors.phone ? 'Додайте ваш номер, ми зателефонуємо' : ''}
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          autoComplete="tel"
        />

        <div className="form__wrapper-title">Email</div>

        <input
          className={`form-input ${errors.email ? 'error' : ''}`}
          placeholder={errors.email ? 'Введіть вашу пошту' : ''}
          type="text"
          name="email"
          value={formData.email}
          onChange={handleChange}
          autoComplete="email"
        />

        <div className="form__wrapper-title">Деталі</div>

        <textarea
          rows={3}
          className={`form-input form-input-big ${errors.comment ? 'error' : ''}`}
          placeholder={errors.comment ? 'Нам треба дізнатись вашу думку' : ''}
          type="text"
          name="comment"
          value={formData.comment}
          onChange={handleChange}
          autoComplete="off"
        />

        <label className={`form-input-label ${errors.agreement ? 'error' : ''}`}>
          <input type="checkbox" name="agreement" checked={formData.agreement} onChange={handleChange} />
          <span className="custom-checkbox"></span>
          <div className="inputs__form-label-wrapper">
            *Я прочитав і зрозумів {}
            <Link to={'/privacy-policy'} className="inputs__form-label-link">
              положення про конфіденційність
            </Link>
            {} і погоджуюся отримувати інформаційний бюлетень та іншу маркетингову інформацію, як зазначено в ньому.
          </div>
        </label>

        <button disabled={openAlert} className={`form-button ${openAlert && isIpad ? 'form-button-alert' : ''}`} type="submit">
          {openAlert && isIpad ? 'Ми отримали ваше повідомлення' : 'Відправити'}
        </button>
      </form>
    </div>
  );
}
