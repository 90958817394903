import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Container.css';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

export default function Container({ children }) {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [off, setOff] = useState(true);

  useEffect(() => {
    if (isMobile) {
      if (
        location.pathname === '/privacy-policy' ||
        location.pathname === '/contacts' ||
        location.pathname === '/success' ||
        location.pathname === '/unsuccess' ||
        location.pathname === '/thin' ||
        location.pathname === '/' ||
        location.pathname === '/thick'
      ) {
        setOff(true);
      } else {
        setOff(false);
      }
    }
  }, [location, isMobile]);

  return (
    <div className={`container ${location.pathname === '/' && isMobile ? 'container-mobile' : ''}`}>
      <Header />
      {children}
      {off && <Footer />}
    </div>
  );
}
