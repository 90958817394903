import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Item.css';

export default function Item({ item }) {
  const location = useLocation();

  return (
    <li>
      <Link to={item.link.split('/').pop()} className="item">
        <div className="item__img">
          <img src={item.img} alt="Флакон" />
        </div>
        <div className="item__title">{`Флакон для парфумів ${location.pathname === '/thin' ? 'тонке' : 'товсте'} скло ${item.ml} мл, ${
          item.color === 'black' ? 'чорна' : item.color === 'gold' ? 'золота' : item.color === 'silver' ? 'срібна' : ''
        } кришка`}</div>
        <div className="item__price">
          <div>від {item.opt500 ? item.opt500 : item.opt200} грн.</div>
          <div className="item__button">
            <img src="./img/icons/basket.svg" alt="Кошик" />
          </div>
        </div>
      </Link>
    </li>
  );
}
