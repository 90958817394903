import React from 'react';
import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
  return (
    <div className="privacy">

      <h2>Доставка</h2>

      <p>компанією-експедитором ТОВ «Нова Пошта» шляхом адресної доставки Покупцеві або доставки до відділення «Нової Пошти» з подальшим самостійним отриманням товару Покупцем.</p>

      <p>Доставка здійснюється за тарифами компаній-експедиторів.</p>

      <h2>Оплата</h2>

      <p>Покупець може оплатити (попередньо оплатити) замовлений Товар наступними способами:</p>

      <p>- За допомогою платіжного сервісу (платіжної системи, платіжного інструменту) WayForPay (або аналогічного), шляхом переходу з Сайту до цього платіжного сервісу.</p>
      <p>- За допомогою переказу грошей на банківський поточний рахунок Продавця, що вказаний в наданому Продавцем Покупцю будь-яким способом рахунку.</p>
      <p>- Накладним платежем при отриманні замовленого Товару в відділені (представництві) служби доставки</p>

      <h2>Обмін та повернення товару</h2>
      <p>
        - Покупець має право протягом 14 (чотирнадцяти) календарних днів не рахуючи дня купівлі Товару належної якості, який представлений на Сайті, обміняти його на аналогічний
        Товар, представлений на Сайті інших розміру, форми, кольору тощо (крім випадків встановлених нормативно-правовими актами або Договором). У разі наявності різниці в ціні між
        Товаром належної якості, що обмінюється і Товаром, на який здійснюється обмін, Покупець проводить необхідний перерахунок з Продавцем.
      </p>
      <p>
        - У випадку, коли у Продавця в момент вимоги Покупця щодо обміну Товару належної якості немає необхідного для обміну Товару, Покупець має право повернути придбаний Товар
        належної якості Продавцеві та одержати сплачену за Товар грошову суму в повному обсязі.
      </p>

      <p>
        - За умови дотримання вимог цього Договору повернення Покупцеві сплаченої за Товар належної якості суми коштів здійснюється після отримання Продавцем від Покупця
        повернутого Товару в порядку, що вказаний на Сайті або в порядку, що окремо узгоджений між Сторонами цього Договору.
      </p>
      <p>
        - Обміну або поверненню у вказані вище строки щодо відповідного виду Товару підлягає лише придбаний у Продавця Товар, який не був у споживанні, не був у використанні (який
        не споживався, не використовувався), який не містить слідів використання чи споживання, а також за умови, що збережено товарний вид такого Товару, збережені споживчі
        властивості Товару, збереглися в належному вигляді ярлики Товару, зберіглося в належному вигляді пакування (упаковка) Товару, і коли Покупець надав Продавцю докази
        придбання Товару в Продавця.
      </p>
      <p>
        - У будь-якому випадку Продавець має право відмовити Покупцю в поверненні або обміні Товару, якщо: Товар був у вживанні і має сліди використання споживачем (потертості,
        плями тощо) та/або Товар укомплектований не в повному обсязі і не збережена фабрична упаковка (пакування) Товару та/або не збережені всі ярлики і заводське маркування
        Товару та/або Товар втратив товарний вигляд і свої споживчі властивості.
      </p>

      <h2>Конфіденційність та захист персональних даних</h2>

      <p>
        - Здійснюючи Замовлення на Сайті, Покупець добровільно надає свою згоду Адміністрації сайту на збір і обробку (накопичення, зберігання, відновлення, використання,
        поширення, знищення) зазначених їм даних, а саме: прізвище, ім’я, по батькові, електронна пошта, телефон, адреса , з метою забезпечення відносин в сфері купівлі-продажу,
        відносин у сфері захисту прав споживачів, у сфері рекламних і маркетингових досліджень, а також дає свою згоду на передачу (поширення) його даних перевізникам,
        транспортно-експедиторським і кур’єрським організаціям, іншим третім особам (без обмеження) на розсуд Адміністрації сайту, а також на вчинення інших дій, передбачених
        Законом України «Про захист персональних даних», без обмеження строку дії такої згоди.
      </p>
      <p>
        - На основі такої згоди Покупця щодо обробки його персональних даних Продавцю дозволяється розголошувати (передавати, поширювати, розкривати) отриману від Покупця
        інформацію та персональні данні Покупця, які Покупець надав Продавцю добровільно при укладені цього Договору, у тому числі, але не виключно Продавцю дозволяється надання
        таких інформації та персональних даних Покупця контрагентам Продавця, іншим третім особам, з метою забезпечення реалізації відносин купівлі-продажу, у сфері захисту прав
        споживачів, у сфері реклами і маркетингових досліджень, у сфері просування товарів (з метою просування, рекламування товарів), з іншою не забороненою законом метою, а також
        Продавцю дозволяється передавати (поширювати, розголошувати, розкривати) таку інформацію і персональні дані Покупця транспортно-експедиторським і кур’єрським організаціям,
        службам доставки, перевізникам, поштовим службам, потовим компаніям, транспортним компаніям, будь-яким банкам і/або фінансовим установам та іншим третім особам (без
        обмеження) на розсуд Продавця з метою доставки, відправки, просування Товару (товарів), і з іншою не забороненою законом метою.
      </p>
      <p>
        - Продавцю дозволяється розголошувати (передавати, поширювати, розкривати) отриману від Покупця інформацію та персональні данні Покупця, які Покупець надав Продавцю
        добровільно при укладені цього Договору у випадках, коли розкриття такої інформації встановлено вимогами чинного законодавства України.
      </p>
    </div>
  );
}
