import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../../Context';
import './Header.css';

export default function Header() {
  const [open, setOpen] = useState(false);
  const { busketNumber, setOne, setTwo, setThree, setFormActive } = useContext(Context);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      document.body.style.overflow = 'hidden';
      document.querySelector('.container').style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.querySelector('.container').style.overflow = 'auto';
    }
    setOpen(false);
  }, [location]);

  return (
    <>
      <header className="header">
        <button onClick={() => setOpen(!open)} className="header__burger">
          <div className="header__burger-line"></div>
          <div className="header__burger-line"></div>
        </button>

        <ul className="header__nav">
          <li>
            <Link to={'/privacy-policy'}>Правила та умови</Link>
          </li>
          <li>
            <button onClick={() => setOpen(!open)} className="header__nav-click">
              Наші товари
            </button>
          </li>
          <li>
            <Link to={'/contacts'}>Контакти</Link>
          </li>
        </ul>

        <Link to={'/'} className="header__logo">
          <img className="img-contain" src="/img/icons/logo.svg" alt="Логотип" />
        </Link>

        <div className="header__basket">
          <Link to={'/payment'} className="header__basket-icon">
            <img src="/img/icons/basket.svg" alt="Кошик" />
            {busketNumber && <div className="header__basket-active"></div>}
          </Link>
        </div>
      </header>
      <div
        onMouseLeave={() => {
          setOpen(false);
        }}
        className={`header__window ${open ? 'header__window-active' : ''}`}
      >
        <Link className={`header__window-item ${open ? 'header__window-item-active' : ''}`} to={'/thin'}>
          Флакон тонке скло
        </Link>

        <Link className={`header__window-item ${open ? 'header__window-item-active' : ''}`} to={'/thick'}>
          Флакон товсте скло
        </Link>

        <Link className={`header__window-item-none header__window-item ${open ? 'header__window-item-active' : ''}`} to={'/contacts'}>
          Контакти
        </Link>

        <Link className={`header__window-item-none header__window-item ${open ? 'header__window-item-active' : ''}`} to={'/privacy-policy'}>
          Правила та умови
        </Link>

        <button
          onClick={() => {
            if (location.pathname !== '/') {
              navigate('/');
            }
            setFormActive(true);
            setOne(true);
            setTwo(true);
            setThree(false);
            setOpen(false);
          }}
          className={`header__window-item-none header__window-item ${open ? 'header__window-item-active' : ''}`}
        >
          Зв’яжіться зі мною
        </button>

        {/* <div className={`header__window-item ${open ? 'header__window-item-active' : ''}`}>
          <a className="header__window-social-icon" href="/" target="blank">
            <img className="img-contain" src="/img/icons/instagram-burger.svg" alt="instagram" />
          </a>

          <a className="header__window-social-icon" href="/" target="blank">
            <img className="img-contain" src="/img/icons/facebook-burger.svg" alt="instagram" />
          </a>
        </div> */}
      </div>
    </>
  );
}
