import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Container from './Container/Container';
import Main from './Main/Main';
import ProductList from './ProductList/ProductList';
import Product from './Product/Product';
import ScrollToTop from '../functions/ScrollToTop';
import Payment from './Payment/Payment';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Contact from './Contact/Contact';
import NotFound from './NotFound/NotFound';
import ThanksPage from './ThanksPage/ThanksPage';

export default function MainRoute() {
  return (
    <Container>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/thin" element={<ProductList />} />
        <Route path="/thick" element={<ProductList />} />
        <Route path="/thin/*" element={<Product />} />
        <Route path="/thick/*" element={<Product />} />
        {/* <Route path="/item" element={<Product />} /> */}
        <Route path="/payment" element={<Payment />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/success" element={<ThanksPage />} />
        <Route path="/unsuccess" element={<ThanksPage />} />
      </Routes>
    </Container>
  );
}
