import React, { useContext, useEffect } from 'react';
import { Context } from '../../Context';
import { useLocation } from 'react-router-dom';
import './ThanksPage.css';
import Button from '../../components/Button/Button';

export default function ThanksPage() {
  const { setBusketNumber } = useContext(Context);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/success') {
      setBusketNumber(false);
      localStorage.removeItem('atomizerItems');
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className="thanks-page">
      <p className="thanks-page__title">{location.pathname === '/success' ? 'Дякуємо!' : 'упс'}</p>
      <p className="thanks-page__description">{location.pathname === '/success' ? 'Ваше замовлення успішно оформлено, ви можете зв’язатись з нами за будь-яких питань.' : 'Сталася помилка з оплатою.'}</p>
      <Button />
    </div>
  );
}
