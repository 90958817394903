import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './Contact.css';

export default function Contact() {
  const isMacBook = useMediaQuery({ maxWidth: 1600 });
  const isIphone = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="contact">
      <h1 className="contact__title">Контакти</h1>
      <div className="contact__info-wrapper">
        <div className="contact__info">
          <div className="contact__info-icon-map">
            <img className="img-contain" src="/img/icons/map.svg" alt="адреса" />
          </div>
          <a href="https://maps.app.goo.gl/wMSw5Q9REw9pEQV47" target="blank">
            вул. Дніпровські Пороги 15і, кв. 20, м. Запоріжжя, Україна
          </a>
        </div>

        <div className="contact__info">
          <div className="contact__info-icon-tel">
            <img className="img-contain" src="/img/icons/tel.svg" alt="телефон" />
          </div>
          <a href="tel:+380634668882">+38 (063) 466-88-82</a>
        </div>
      </div>
      <div className="contact__info-wrapper">
        <div className="contact__info">
          <div className="contact__info-icon-mail">
            <img className="img-contain" src="/img/icons/mail.svg" alt="пошта" />
          </div>
          <a href="mailto:kajrosm0@gmail.com">kajrosm0@gmail.com</a>
        </div>

        <div className="contact__info">
          <a href="https://t.me/+380634668882" target="_blank" rel="noopener noreferrer" className="contact__info-icon-social">
            <img className="img-contain" src="/img/icons/telegram.svg" alt="телеграм" />
          </a>
          {isIphone && (
            <a href="viber://chat?number=%2B380634668882" target="_blank" rel="noopener noreferrer" className="contact__info-icon-social">
              <img className="img-contain" src="/img/icons/viber.svg" alt="вайбер" />
            </a>
          )}
        </div>
      </div>

      <div className="contact__info-card">
        <iframe
          title={`'Запоріжжя' Store Location`}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2675.285174889365!2d35.074311676409195!3d47.892165471217425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dc642f0d30f7d9%3A0x93bf1daaeb73a9b1!2z0LLRg9C70LjRhtGPINCU0L3RltC_0YDQvtCy0YHRjNC60ZYg0J_QvtGA0L7Qs9C4LCAxNSwgMjAsINCX0LDQv9C-0YDRltC20LbRjywg0JfQsNC_0L7RgNGW0LfRjNC60LAg0L7QsdC70LDRgdGC0YwsIDY5MDAw!5e0!3m2!1sru!2sua!4v1722937171290!5m2!1sru!2sua"
          width={isIphone ? '100%' : isMacBook ? '470' : '730'}
          height={isIphone ? '250px' : isMacBook ? '252' : '390'}
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>

        <div className="contact__info-card-text">
          <div className="contact__info-card-text-title">Трохи додаткової інформації:</div>
          <ul className="contact__info-card-text-list">
            <li>· Повне найменування ATOMIZER SHOP, назва підприємства ТОВ Кайрос-М;</li>
            <li>· Ідентифікаційний код юридичної особи: 45187884;</li>
            <li>· Індивідуальний податковий номер 451878808284;</li>
            <li>· Юридична адреса - Дніпровські Пороги 15і, кв. 20, м. Запоріжжя, Україна.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
