import React, { useEffect, useState } from 'react';
import Item from '../../components/Item/Item';
import { useLocation } from 'react-router-dom';
import { data } from '../../components/data';
import './ProductList.css';

export default function ProductList() {
  const [list, setList] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const newFilteredData = data.filter((product) => product.category === location.pathname.substring(1));
    setList(newFilteredData);
    // eslint-disable-next-line
  }, [location]);

  return (
    <div className="product-list">
      <p className="product-list__title">Флакони для парфумів</p>
      <p className="product-list__description">{`з розпилювачем ${location.pathname === '/thin' ? 'тонке скло' : 'товсте скло'}`}</p>
      <ul className="product-list__items">
        {list &&
          list.map((item, index) => {
            return <Item key={index} item={item} />;
          })}
      </ul>
    </div>
  );
}
