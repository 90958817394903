import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { data } from '../../components/data';
import { Context } from '../../Context';
import './Product.css';

const colorsOne = [
  { value: 'black', label: 'чорний' },
  { value: 'silver', label: 'сріблястий' },
  { value: 'gold', label: 'золотий' },
];

const colorsTwo = [
  { value: 'silver', label: 'сріблястий' },
  { value: 'gold', label: 'золотий' },
];

// тонкое
const mlThin = [
  { value: '2', label: '2 мл' },
  // { value: '3', label: '3 мл' },
  { value: '5', label: '5 мл' },
  { value: '10', label: '10 мл' },
];
// толстое
const mlThick = [
  // { value: '4', label: '4 мл' },
  { value: '6', label: '6 мл' },
  { value: '10', label: '10 мл' },
  { value: '15', label: '15 мл' },
  { value: '20', label: '20 мл' },
  { value: '30', label: '30 мл' },
];

// черный
const mlBlack = [{ value: '4', label: '4 мл' }];

export default function Product() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    // eslint-disable-next-line
  }, []);

  const { setBusketNumber } = useContext(Context);
  const [item, setItem] = useState(false);

  const [quantity, setQuantity] = useState(1);

  const [priceTable, setPriceTable] = useState([]);

  const [color, setColor] = useState([]);

  const [ml, setMl] = useState([]);

  const [sum, setSum] = useState(0);

  // открытие сетки цен на телефоне
  const [openSize, setOpenSize] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    if (value <= 100000) {
      setQuantity(value);
    }
  };

  useEffect(() => {
    let total;

    if (item.category === 'thick') {
      if (quantity < 50) {
        total = item.price * quantity;
      } else if (quantity >= 50 && quantity < 100) {
        total = item.opt50 * quantity;
      } else if (quantity >= 100 && quantity < 200) {
        total = item.opt100 * quantity;
      } else if (quantity >= 200) {
        total = item.opt200 * quantity;
      }
    } else {
      if (quantity < 50) {
        total = item.price * quantity;
      } else if (quantity >= 50 && quantity < 100) {
        total = item.opt50 * quantity;
      } else if (quantity >= 100 && quantity < 200) {
        total = item.opt100 * quantity;
      } else if (quantity >= 200 && quantity < 500) {
        total = item.opt200 * quantity;
      } else if (quantity >= 500) {
        total = item.opt500 * quantity;
      }
    }

    if (total % 1 === 0) {
      total = total.toFixed(0);
    } else {
      total = total.toFixed(6);
    }

    // Преобразуем обратно в число
    total = parseFloat(total);

    setSum(total);
  }, [quantity, item]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const updatePriceTable = (product) => {
      const baseTable = [
        { quantity: 'Кількість', price: 'Ціна за шт.' },
        { quantity: '1 - 49', price: `${product.price} грн.` },
        { quantity: '50 - 99', price: `${product.opt50} грн.` },
      ];

      const thickTable = [...baseTable, { quantity: '100 - 199', price: `${product.opt100} грн.` }, { quantity: '200 +', price: `${product.opt200} грн.` }];

      const thinTable = [
        ...baseTable,
        { quantity: '100 - 199', price: `${product.opt100} грн.` },
        { quantity: '200 - 499', price: `${product.opt200} грн.` },
        { quantity: '500 +', price: `${product.opt500} грн.` },
      ];

      return product.category === 'thick' ? thickTable : thinTable;
    };

    const product = data.find((product) => product.link === location.pathname.replace('/', ''));
    if (product) {
      setItem(product);
      setPriceTable(updatePriceTable(product));
    } else {
      navigate('/404');
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (
      location.pathname === '/thick/6ml-silver' ||
      location.pathname === '/thick/6ml-gold' ||
      location.pathname === '/thick/10ml-silver' ||
      location.pathname === '/thick/10ml-gold' ||
      location.pathname === '/thick/15ml-silver' ||
      location.pathname === '/thick/15ml-gold' ||
      location.pathname === '/thick/20ml-silver' ||
      location.pathname === '/thick/20ml-gold' ||
      location.pathname === '/thick/30ml-silver' ||
      location.pathname === '/thick/30ml-gold'
    ) {
      setColor(colorsTwo);
    } else {
      setColor(colorsOne);
    }

    if (location.pathname === '/thick/4ml-black') {
      setMl(mlBlack);
    } else if (location.pathname.split('/')[1] === 'thin') {
      setMl(mlThin);
    } else {
      setMl(mlThick);
    }

    // eslint-disable-next-line
  }, [location]);

  const handleAddBusket = async () => {
    let storedItems = await localStorage.getItem('atomizerItems');

    // Создаем объект с id и size, если size определен
    const data = {
      ...item,
      quantity,
      sum,
    };

    setBusketNumber(true);

    if (storedItems) {
      const parsedItems = JSON.parse(storedItems);
      const updatedItems = [...parsedItems, data];
      localStorage.setItem('atomizerItems', JSON.stringify(updatedItems));
    } else {
      const updatedItems = [data];
      localStorage.setItem('atomizerItems', JSON.stringify(updatedItems));
    }
  };

  // механизм чтобы закрывать в телефоне сетку с ценами
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY < prevScrollY) {
        setOpenSize(false);
      }
      setPrevScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY, setOpenSize]);

  return (
    <div className="product">
      <div className="product__left">
        <div className="product__left-img">{item && <img src={item.img} alt="Флакон" />}</div>
      </div>
      <div className="main__line"></div>
      <div className="product__right">
        <h1 className="product__box-title">Флакон для парфумів</h1>
        <button
          onClick={() => {
            setOpenSize(!openSize);
            window.scrollTo({
              top: document.documentElement.scrollHeight,
              behavior: 'smooth',
            });
          }}
          className="product__box-size-mobile"
        >
          Сітка цін
        </button>
        <h2 className="product__box-description">{`з розпилювачем ${item.category === 'thick' ? 'товсте скло' : 'тонке скло'}`}</h2>
        <div className={`product__box-size ${openSize ? 'product__box-size-mobile-open' : ''}`}>
          <div className="product__box-size-line"></div>
          {priceTable.map((item, index) => (
            <div className="product__box-size-wrapper" key={index}>
              <div className="product__box-size-wrapper-left">{item.quantity}</div>
              <div className="product__box-size-wrapper-right">{item.price}</div>
            </div>
          ))}
        </div>
        <div className="product__box-other product__box-other-none">Введіть кількість для замовлення:</div>
        <div className="product__box-price">
          <input id="quantity" name="quantity" value={quantity} onChange={handleChange} placeholder="1 шт." />
          <div className="product__box-price-number">{sum} грн.</div>
        </div>
        <div className="product__box-other">Розмір:</div>
        <ul className="product__box-ml">
          {ml.map((ml, index) => (
            <li className={`${ml.value === item.ml ? 'product__box-ml-active' : ''}`} key={index}>
              <Link to={location.pathname.replace(/\/\d+ml/, `/${ml.value}ml`)}>{ml.label}</Link>
            </li>
          ))}
        </ul>
        <div className="product__box-other">Колір:</div>
        <ul className="product__box-ml">
          {color.map((color, index) => (
            <li className={`${color.value === item.color ? 'product__box-ml-active' : ''}`} key={index}>
              <Link to={location.pathname.replace(/-black|-gold|-silver/, `-${color.value}`)}>{color.label}</Link>
            </li>
          ))}
        </ul>
        <div className="product__box-other">Деталі:</div>
        <p className="product__box-text">спеціальний невеликий атомайзер, який може бути:</p>
        {/* <p className="product__box-text">{item.category === 'thick' ? '6 мл, 10 мл, 15 мл, 20 мл та 30 мл.' : '2 мл, 3 мл, 5 мл, та 10 мл.'}</p> */}
        <p className="product__box-text">{item.category === 'thick' ? '6 мл, 10 мл, 15 мл, 20 мл та 30 мл.' : '2 мл, 5 мл, та 10 мл.'}</p>
        <div className="product__box-other-mobile">Кількість:</div>
        <Link
          to={quantity !== '0' && quantity !== '' ? '/payment' : location.pathname}
          onClick={quantity !== '0' && quantity !== '' ? () => handleAddBusket() : null}
          className="product__box-button"
        >
          Додати до кошика
          <div className="product__box-button-img">
            <img className="img-contain" src="/img/icons/arrow.svg" alt="Стрілка додавання" />
          </div>
        </Link>
      </div>
    </div>
  );
}
