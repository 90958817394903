import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';

export default function Footer() {
  const { three } = useContext(Context);
  const [activeFooter, setActiveFooter] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveFooter(false);
    } else {
      setActiveFooter(true);
    }
  }, [location]);

  return (
    <footer className={`footer ${activeFooter ? 'footer-active' : ''} ${three ? 'footer-top' : ''}`}>
      <div className="footer__left">
        <div className="footer__left-img">
          <img className="img-contain" src="/img/icons/logo-footer.svg" alt="Логотип" />
        </div>
        <ul className="footer__left-text">
          <li>Copyright © 2024 ATOMIZERSHOP</li>
          <li>
            <a href="https://circle.in.ua" target="blank">
              Made by circle.studio
            </a>
          </li>
        </ul>
      </div>
      <div className="footer__right">
        <div className="footer__right-wrapper">
          <div className="footer__right-text">
            <div className="footer__right-text-title">Наші товари</div>
            <ul className="footer__right-text-link">
              <li>
                <Link to={'/thin'}>Флакон тонке скло</Link>
              </li>
              <li>
                <Link to={'/thick'}>Флакон товсте скло</Link>
              </li>
            </ul>
          </div>
          <div className="footer__right-text">
            <div className="footer__right-text-title">Контакти</div>
            <ul className="footer__right-text-link">
              <li>
                <a href="tel:+380634668882">+38 063 466 88 82</a>
              </li>
              <li>
                <a href="mailto:kajrosm0@gmail.com">kajrosm0@gmail.com</a>
              </li>
            </ul>
          </div>
        </div>
        <Link to={'/privacy-policy'} className="footer__right-link">
          Політика конфіденційності
        </Link>

        <ul className="footer__left-text-mobile">
          <li>Copyright © 2024 ATOMIZERSHOP</li>
          <li>
            <a href="https://circle.in.ua" target="blank">
              Made by circle.studio
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
