import React, { useContext, useEffect, useState } from 'react';
import './Main.css';
import Form from '../../components/Form/Form';
import { data } from '../../components/data';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../Context';

export default function Main() {
  const { one, setOne, two, setTwo, three, setThree, formActive, setFormActive } = useContext(Context);

  const [page, setPage] = useState(data);
  const [product, setProduct] = useState(0);
  const [activeScale, setActiveScale] = useState(false);

  const [time, setTime] = useState(true);

  const [circle, setCircle] = useState(true);

  const isIpad = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    const newFilteredData = data.filter((product) => product.category === (!one ? 'thin' : 'thick') && product.color === 'gold');
    setPage(newFilteredData);
    setProduct(0);

    setCircle(true);
    setTimeout(() => {
      setCircle(false);
    }, 500);
    // eslint-disable-next-line
  }, [one]);

  // листаем подкатегории
  const handleNext = (index) => {
    setActiveScale(true);
    setProduct(index);

    setTimeout(() => {
      setActiveScale(false);
    }, 300);
  };

  // дополительный переключатель
  const [scrollValues, setScrollValues] = useState(false);

  // мотор переключения
  const handleWheel = (event) => {
    const flagOne = scrollValues[0] < scrollValues[1] + 1;
    const flagTwo = scrollValues[0] > scrollValues[1] - 1;

    const deltaY = event.deltaY;

    // Добавляем значение прокрутки в массив
    setScrollValues((prevValues) => {
      // Создаем новый массив с новыми значениями прокрутки
      const newValues = [prevValues[1], deltaY];
      return newValues;
    });

    if (time) {
      setTime(false);

      const deltaY = event.deltaY;

      if (deltaY > 0 && !one && flagOne) {
        setOne(true);
        setScrollValues(false);
        // второе
      } else if (deltaY > 0 && one && !two && flagOne) {
        setTwo(true);
        setScrollValues(false);
        setFormActive(true);
        // форма
      } else if (deltaY > 0 && one && two && !three && flagOne) {
        setThree(true);
        setScrollValues(false);

        // футер
      } else if (deltaY < 0 && one && two && three && flagTwo) {
        setThree(false);
        setScrollValues(false);
      } else if (deltaY < 0 && one && two && !three && flagTwo) {
        setTwo(false);
        setScrollValues(false);

        setFormActive(false);
      } else if (deltaY < 0 && one && !two && !three && flagTwo) {
        setOne(false);
        setScrollValues(false);
      }

      setTimeout(() => {
        setTime(true);
      }, 200);
    }
  };

  // для телефона
  // чуствительность срабатывания на телефоне
  const threshold = 7;

  const [previousY, setPreviousY] = useState(0);

  const handleTouchMove = (event) => {
    if (time) {
      setTime(false);

      const currentY = event.touches[0].clientY;
      const deltaY = currentY - previousY;

      if (Math.abs(deltaY) >= threshold) {
        if (deltaY < 0) {
          // Логика прокрутки вверх
          if (!one) {
            setOne(true);
            setScrollValues(false);
          } else if (one && !two) {
            setTwo(true);
            setScrollValues(false);
            setFormActive(true);
          } else if (one && two && !three) {
            setThree(true);
            setScrollValues(false);
          }
        } else if (deltaY > 0) {
          // Логика прокрутки вниз
          if (one && two && three) {
            setThree(false);
            setScrollValues(false);
          } else if (one && two && !three) {
            setTwo(false);
            setScrollValues(false);
            setFormActive(false);
          } else if (one && !two && !three) {
            setOne(false);
            setScrollValues(false);
          }
        }

        setPreviousY(currentY);
      }

      setTimeout(() => {
        setTime(true);
      }, 200);
    }
  };

  const handleTouchStart = (event) => {
    setPreviousY(event.touches[0].clientY);
  };

  useEffect(() => {
    if (time) {
      window.addEventListener('wheel', handleWheel);
      window.addEventListener('touchstart', handleTouchStart);
      window.addEventListener('touchmove', handleTouchMove);
      return () => {
        window.removeEventListener('wheel', handleWheel);
        window.removeEventListener('touchstart', handleTouchStart);
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [one, two, three, time, previousY]);

  return (
    <main className={`main ${three ? 'main-active' : ''}`}>
      <Form props={formActive} />

      <div className={`main__big-circle ${circle ? 'main__big-circle-active' : ''} ${one ? 'main__big-circle-color' : ''}`}></div>
      <div className={`main__big-circle-img-one ${one ? 'main__big-circle-img-one-active' : ''} ${one ? 'main__big-circle-color' : ''}`}>
        <img src="/img/icons/iPad-one.png" alt="флакон" />
      </div>
      <div className="main__big-circle-img-two">
        <img src="/img/icons/iPad-two.png" alt="флакон" />
      </div>
      <div className={`main__small-circle ${circle ? 'main__small-circle-active' : ''} ${one ? 'main__big-circle-color' : ''} ${three ? 'main__small-circle-footer' : ''}`}></div>

      <div className="main__box">
        <div className={`main__box-item-left ${one ? 'main__box-item-left-active' : ''}`}>
          {/* <div className="main__box-item-best">bestseller</div> */}
          <h1 className="main__box-item-title">Флакон для парфумів</h1>
          <h2 className="main__box-item-description">з розпилювачем тонке скло</h2>
          <p className="main__box-item-price">від ₴10</p>
          <div className="main__box-item-detail">Деталі:</div>
          <p className="main__box-item-characteristic">спеціальний невеликий атомайзер, який може бути:</p>
          <p className="main__box-item-characteristic">2 мл, 3 мл, 5 мл та 10 мл.</p>
          <div className="main__box-item-arrow">
            <Link to="/thin">Переглянути всі товари</Link>
            <div className="main__box-item-arrow-icon">
              <img className="img-contain" src="./img/icons/arrow.svg" alt="Стрілка" />
            </div>
          </div>
        </div>

        <div className={`main__box-item-left-bottom ${one ? 'main__box-item-left-bottom-active' : ''}`}>
          {/* <div className="main__box-item-best">bestseller</div> */}
          <h1 className="main__box-item-title">Флакон для парфумів</h1>
          <h2 className="main__box-item-description">з розпилювачем товсте скло</h2>
          <p className="main__box-item-price">від ₴16.50</p>
          <div className="main__box-item-detail">Деталі:</div>
          <p className="main__box-item-characteristic">спеціальний невеликий атомайзер, який може бути:</p>
          <p className="main__box-item-characteristic">6 мл, 10 мл, 15 мл, 20 мл та 30 мл.</p>
          <div className="main__box-item-arrow">
            <Link to="/thick">Переглянути всі товари</Link>
            <div className="main__box-item-arrow-icon">
              <img className="img-contain" src="./img/icons/arrow.svg" alt="Стрілка" />
            </div>
          </div>
        </div>
      </div>

      <div className="main__line"></div>

      {!isIpad ? (
        <div className="main__box">
          <div className="main__box-item">
            <Link to={page[product].link} className={`main__box-item-cart ${activeScale ? 'main__box-item-cart-active' : ''}`}>
              <div className="main__box-item-cart-img">
                <img src={page[product].img} alt="флакон" />
              </div>
              <div className="main__box-item-cart-info">
                <div className="main__box-item-cart-info-title">
                  {one ? `Флакон для парфумів товсте скло ${page[product].ml} мл.` : `Флакон для парфумів тонке скло ${page[product].ml} мл.`}
                </div>
                <div className="main__box-item-cart-info-price">від ₴{page[product].opt500 ? page[product].opt500 : page[product].opt200}</div>
              </div>
            </Link>

            <ul className="main__box-item-circle-wrapper">
              {page &&
                page.map((item, index) => (
                  <li onClick={() => handleNext(index)} key={index} className="main__box-item-circle">
                    <div className={`main__box-item-circle-small ${product === index ? 'main__box-item-circle-small-active' : ''}`}></div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ) : (
        <ul className="main__mobile-wrapper">
          {page &&
            page.map((item, index) => (
              <li key={index}>
                <Link to={item.link} className={`main__box-item-cart ${index === 0 ? 'first' : ''} ${index === page.length - 1 ? 'last' : ''}`}>
                  <div className="main__box-item-cart-img">
                    <img src={item.img} alt="флакон" />
                  </div>
                  <div className="main__box-item-cart-info">
                    <div className="main__box-item-cart-info-title">
                      {one ? `Флакон для парфумів товсте скло ${item.ml} мл.` : `Флакон для парфумів тонке скло ${item.ml} мл.`}
                    </div>
                    <div className="main__box-item-cart-info-price">від ₴{item.opt500 ? item.opt500 : item.opt200}</div>
                  </div>
                </Link>
              </li>
            ))}
        </ul>
      )}

      <div className="main__navigate-wrapper">
        <button
          onClick={() => {
            setOne(false);
            setTwo(false);
            setThree(false);
            setFormActive(false);
          }}
          className={`main__navigate ${!one ? 'main__navigate-active' : ''}`}
        ></button>
        <button
          onClick={() => {
            setOne(true);
            setTwo(false);
            setThree(false);
            setFormActive(false);
          }}
          className={`main__navigate ${!two && one ? 'main__navigate-active' : ''}`}
        ></button>
        <button
          onClick={() => {
            setOne(true);
            setTwo(true);
            setThree(false);
            setFormActive(true);
          }}
          className={`main__navigate ${two && !three ? 'main__navigate-active' : ''}`}
        ></button>
        <button
          onClick={() => {
            setOne(true);
            setTwo(true);
            setThree(true);
          }}
          className={`main__navigate ${three ? 'main__navigate-active' : ''}`}
        ></button>
      </div>
    </main>
  );
}
