import React, { useContext } from 'react';
import { Context } from '../../../Context';
import './PayProductList.css';

export default function PayProductList(props) {
  const { setBusketNumber } = useContext(Context);

  const { list, setItems } = props;

  const handleDelete = (item) => {
    const updatedList = list.filter((listItem) => listItem !== item);
    setItems(updatedList);
    localStorage.setItem('atomizerItems', JSON.stringify(updatedList));
    if (updatedList.length < 1) setBusketNumber(false);
  };

  return (
    <ul className="payment__productList">
      {list.map((item, index) => {
        return (
          <li key={index} className="payment__productList-item">
            <div className="payment__productList-item-img">
              <img src={item.img} alt={item.category} />
            </div>
            <div className="payment__productList-item-info">
              <div className="payment__productList-item-info-title-wrapper">
                <p className="payment__productList-item-info-title">
                  {item.category === 'thick' ? 'ФЛАКОН ДЛЯ ПАРФУМІВ З РОЗПИЛЮВАЧЕМ ТОвсте СКЛО' : 'ФЛАКОН ДЛЯ ПАРФУМІВ З РОЗПИЛЮВАЧЕМ ТОНКЕ СКЛО'}
                </p>
                <button onClick={() => handleDelete(item)} className="payment__productList-item-info-title-cross">
                  <img className="img-contain" src="/img/icons/cross.svg" alt="відалити" />
                </button>
              </div>
              <div className="payment__productList-item-info--inner">
                <p className="payment__productList-item-info-text">колір: {item.color === 'black' ? 'чорний' : item.color === 'silver' ? 'срібний' : 'золотий'}</p>
                <p className="payment__productList-item-info-text">розмір: {item.ml} мл</p>
              </div>
              <div className="payment__productList-item-info-price-wrapper">
                <div className="payment__productList-item-info-quantity">{item.quantity} шт.</div>
                <p className="payment__productList-item-info-price">₴ {item.sum}</p>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
